/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

.ion-color-spotify {
  --ion-color-base: var(--ion-color-spotify);
  --ion-color-base-rgb: var(--ion-color-spotify-rgb);
  --ion-color-contrast: var(--ion-color-spotify-contrast);
  --ion-color-contrast-rgb: var(--ion-color-spotify-contrast-rgb);
  --ion-color-shade: var(--ion-color-spotify-shade);
  --ion-color-tint: var(--ion-color-spotify-tint);
}

.session-plan-cards {
  display: flex;
  flex-wrap: wrap;
}

.session-plan-card {
  display: flex;
  margin: 0.25rem;
  background-color: var(--ion-color-secondary);
  transition: 0.3s all;
  border-radius: 0;
  position: relative;
  width: 100%;

  @media screen and (min-width: 768px) {
    width: calc(50% - 0.5rem);
  }

  @media screen and (min-width: 1200px) {
    width: calc(33.3% - 0.5rem);
  }

  .bg-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.2s all;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &:hover {
    .bg-image {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }

  .icon {
    position: absolute;
    color: #fff;
    font-size: 2em;
    top: 10px;
    right: 10px;
    z-index: 3;
    background-color: rgba(#fff, 0.2);
    border-radius: 20px;
    padding: 10px;
  }

  ion-thumbnail {
    width: 58px;
  }

  ion-card-content {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    color: #fff;
    min-height: 200px;
    background: linear-gradient(rgba(#000, 0.1), #000);
    height: 100%;
    width: 100%;
    transition: 0.2s all;
    z-index: 2;

    &:hover {
      background: linear-gradient(rgba(#000, 0.4), #000);

      h2 {
        margin-bottom: 1rem;
      }
    }

    h2 {
      font-size: 24px;
      width: 100%;
      margin-top: auto;
      margin-bottom: 0.5rem;
      transition: 0.2s all;
      font-weight: bold;

      span {
        text-align: center;
        display: block;
        font-size: 16px;
        font-weight: normal;
      }
    }
  }

  &:hover {
    background-color: var(--ion-color-primary);
  }
}

.login-header,
.login-background {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}

.login-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 50%;
}

.login-container {
  background-color: var(--ion-background-color);

  &.continue {
    text-align: center;
    padding: 20px;
  }
}

.login-container-wrapper {
  position: relative;
  min-height: 200px;
  width: 100%;
  margin-top: 40px;
}

.import-button {
  width: 100%;
  div {
    background: none;
    width: 100%;
    button {
      background: none;
      width: 100%;
    }
  }
}

.holder {
  margin-top: 50px;
}

.link-block-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.pdf-content {
  all: unset;
  font-family: var(--ion-font-family);
  letter-spacing: 0.01px;
  pointer-events: none;
  position: absolute;
  bottom: 100%;
  width: 1000px;
  font-size: 20px;
  line-height: 1;

  strong,
  b {
    font-weight: bold;
  }

  ul {
    li {
      padding-left: 10px;
    }
  }

  a {
    color: var(--ion-background-color);
  }

  .text-row {
    text-align: center;
    margin-bottom: 30px;
  }

  .row {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    .block {
      flex: 1;
      display: inline-block;

      + .block {
        margin-left: 20px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  ion-modal.pdf-modal::part(content) {
    --height: 90%;
    position: absolute;
    top: 5%;
    --width: 90%;
    display: block;
  }
}
